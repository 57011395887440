import { Controller } from "@hotwired/stimulus"
import GivingFilterController from './giving_filter_controller'
import ChangeFilterModel from '../../searches/change_filter/model'
import debounce from "debounce"

export default class extends GivingFilterController {
  static targets = [
    'amountRange',
    'amountRangeStart',
    'amountRangeEnd',
    'dateRange',
    'dateRangeStart',
    'dateRangeEnd',
    'dateModeField',
    'lookbackRange',
    'lookbackRangeStart',
    'lookbackRangeEnd',
    'fundsField',
    'fundCategoryId',
    'removed',
    'summary'
  ]

  connect() {
    this.registerGivingFilter()
    this.setEstPosition()

    this.model = new ChangeFilterModel({
      position: this.position
    })

    this.updateModel()

    if (this.data.has('minimized') && this.data.get('minimized') == 1) {
      this.collapse()
    }
  }

  updateModel() {
    this.model.set('amountRangeStart', this.amountRangeStartTarget.value)
    this.model.set('amountRangeEnd',   this.amountRangeEndTarget.value)

    this.updateDateValues()
    this.updateFundValues()

    super.updateModel()
  }
}
