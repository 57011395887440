import { Controller } from "@hotwired/stimulus"
import GivingFilterController from './giving_filter_controller'
import AggregateFilterModel from '../../searches/aggregate_filter/model'
import debounce from "debounce"

export default class extends GivingFilterController {
  static targets = [
    'removed',
    'aggRange',
    'aggRangeStart',
    'aggRangeEnd',
    'aggFunction',
    'aggFunctionField',
    'countRange',
    'countRangeStart',
    'countRangeEnd',
    'dateRange',
    'dateRangeStart',
    'dateRangeEnd',
    'dateModeField',
    'lookbackRange',
    'lookbackRangeStart',
    'lookbackRangeEnd',
    'funds',
    'fundsField',
    'fundCategoryId',
    'summary'
  ]

  connect() {
    this.registerGivingFilter()
    this.setEstPosition()

    this.model = new AggregateFilterModel({
      aggFunction: this.aggFunctionFieldTarget.value,
      position: this.position
    })

    this.updateModel()

    if (this.data.has('minimized') && this.data.get('minimized') == 1) {
      this.collapse()
    }
  }

  updateModel() {
    this.model.set('countRangeStart', this.countRangeStartTarget.value)
    this.model.set('countRangeEnd',   this.countRangeEndTarget.value)
    this.model.set('aggFunction',     this.aggFunctionFieldTarget.value)
    this.model.set('aggRangeStart',   this.aggRangeStartTarget.value)
    this.model.set('aggRangeEnd',     this.aggRangeEndTarget.value)

    this.updateDateValues()
    this.updateFundValues()

    super.updateModel()
  }
}
