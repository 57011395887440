import { Controller } from "@hotwired/stimulus"
import GivingFilterModel from '../../searches/giving_filter/model'
import debounce from "debounce"

export default class extends Controller {
  connect() {}

  registerGivingFilter() {
    window.app.givingFilters = window.app.givingFilters || []
    window.app.givingFilters.push(this)
  }

  setEstPosition() {
    if (!this.data.has('position') || (this.data.get('position') == '0')) {
      let estPosition = window.app.givingFilters.filter(gf => !gf.removed).length
      this.data.set('position', estPosition)
    }
  }

  disconnect() {
    window.app.givingFilters = []
  }

  editFilter(event) {
    event.preventDefault()
    this.expand()
  }

  removeFilter(event) {
    event.preventDefault()

    this.element.classList.add('giving_filter--removed')
    this.data.set('removed', 1)
    this.removedTarget.value = true
    window.app.search.updateFilterPositions()
    window.app.search.setSortables()
  }

  minimize(event) {
    event.preventDefault()
    this.collapse()
  }

  maximize(event) {
    event.preventDefault()
    this.expand()
  }

  collapse() {
    this.element.classList.add('giving_filter--minimized')

    if (!this.model.summarizable) {
      this.emptySummarize()
    }
  }

  expand() {
    this.element.classList.remove('giving_filter--minimized')
  }

  updateModel() {
    this.model.set('position', this.position)

    if (this.model.isValid) {
      this.summarize()
      this.removeErrors()
      window.app.search.setSortables()
    } else {
      this.setErrors()
    }
  }

  updateDateValues() {
    this.model.set('dateRangeStart',     this.dateRangeStartTarget.value)
    this.model.set('dateRangeEnd',       this.dateRangeEndTarget.value)
    this.model.set('dateMode',           this.dateModeFieldTarget.value)
    this.model.set('lookbackRangeStart', this.lookbackRangeStartTarget.value)
    this.model.set('lookbackRangeEnd',   this.lookbackRangeEndTarget.value)
  }

  updateFundValues() {
    this.model.set('fundIds',         this.fundIds)
    this.model.set('fundCategoryIds', this.fundCategoryIds)
  }

  summarize() {
    this.summaryTargets.forEach(elem => elem.textContent = this.model.summary)
  }

  emptySummarize() {
    let msg = "No filters defined"
    this.summaryTargets.forEach(elem => elem.textContent = msg)
  }

  setErrors() {
    this.model.errors.fields.forEach((f) => {
      this[`${f}Targets`].forEach((t) => {
        t.parentNode.classList.add('field_with_errors')
      })
    })

    this.summaryTargets.forEach(elem => elem.textContent = this.model.errors.messages.join(', '))
    this.summaryTargets.forEach(elem => elem.classList.add('text-red-600'))
  }

  removeErrors() {
    this.element.querySelectorAll('.field_with_errors').forEach((elem) => {
      elem.classList.remove('field_with_errors')
    })

    this.summaryTargets.forEach(elem => elem.classList.remove('text-red-600'))
  }

  get removed() {
    return this.removedTarget.value
  }

  get position() {
    return parseInt(this.data.get('position') || 0)
  }

  setPosition(pos) {
    this.data.set('position', pos)
  }

  get fundIds() {
    return [...this.fundsFieldTarget.options]
      .filter(option => option.selected)
      .map(option => option.value)
  }

  get fundCategoryIds() {
    return this.fundCategoryIdTargets
      .filter(b => b.checked)
      .map(b => b.value)
  }

  get sortables() {
    return this.model.sortables
  }

  numberFiltered(targetName) {
    return this[`${targetName}Target`].value.replace(/\$|\%/g, '')
  }
}
