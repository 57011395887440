import Summarizer from './summarizer'
import Validator from './validator'
import Model from '../giving_filter/model.js'

export default class ZeroFilterModel extends Model {
  // Base model overrides

  constructor(attrs) {
    super(attrs)
    this.summarizer = new Summarizer(this)
    this.validator  = new Validator(this)
  }

  // Specific to Zero Filter
  // ------------------------------------------------------------------------ //
  get type()               { return 'zero' }
  get position()           { return this.get('position') }

  // Summary
  // ------------------------------------------------------------------------ //

  get summarizable() {
    return true
  }

  // Sorting
  // ------------------------------------------------------------------------ //

  get sortables() {
    return {}
  }
}
