import Validator from '../giving_filter/validator'

export default class PledgeFilterValidator extends Validator {
  get isValid() {
    this.resetErrors()

    this.validateNumberValue('pledgedRangeStart')
    this.validateNumberValue('pledgedRangeEnd')
    this.validateNumberValue('pledgeReceivedRangeStart')
    this.validateNumberValue('pledgeReceivedRangeEnd')
    this.validateNumberValue('pledgeCompletedRangeStart')
    this.validateNumberValue('pledgeCompletedRangeEnd')

    this.validateRangeValues('pledgedRange', `Pledged amount ${this.rangeInvertedMsg}`)
    this.validateRangeValues('pledgeReceivedRange', `Pledge received ${this.rangeInvertedMsg}`)
    this.validateRangeValues('pledgeCompletedRange', `Pledge completed ${this.rangeInvertedMsg}`)

    return this.errors.fields.length < 1
  }
}
