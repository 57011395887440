import Summarizer from './summarizer'
import Validator from './validator'
import Model from '../giving_filter/model.js'

export default class ChangeFilterModel extends Model {
  // Base model overrides

  constructor(attrs) {
    super(attrs)
    this.summarizer = new Summarizer(this)
    this.validator  = new Validator(this)
  }

  // Specific to Change Filter
  // ------------------------------------------------------------------------ //
  get type()               { return 'change' }

  get position()           { return this.get('position') }

  get hasChangeValues()    { return this.anyPresent(['amountRangeStart', 'amountRangeEnd']) }
  get hasFullChangeRange() { return this.rangePresent('amountRange') }
  get isExactChange()      { return this.rangeExact('amountRange') }

  // Summary
  // ------------------------------------------------------------------------ //

  get summarizable() {
    return this.hasDateValues
  }

  // Sorting
  // ------------------------------------------------------------------------ //

  get sortables() {
    return {
      ...this.recentRangeSortable,
      ...this.priorRangeSortable,
      ...this.percentChangeSortable,
    }
  }

  get recentRangeSortable() {
    let obj = {}
    let aggKey = `recent_range_amount_sum_${this.position}`
    obj[aggKey] = `[${this.position}] Recent Period Giving`

    return obj
  }

  get priorRangeSortable() {
    let obj = {}
    let aggKey = `prior_range_amount_sum_${this.position}`
    obj[aggKey] = `[${this.position}] Previous Period Giving`

    return obj
  }

  get percentChangeSortable() {
    let obj = {}
    let aggKey = `percent_change_${this.position}`
    obj[aggKey] = `[${this.position}] Percent Change`

    return obj
  }
}
