import Summarizer from './summarizer'
import Validator from './validator'
import Model from '../giving_filter/model.js'

export default class ScoreFilterModel extends Model {
  // Base model overrides

  constructor(attrs) {
    super(attrs)
    this.summarizer = new Summarizer(this)
    this.validator  = new Validator(this)
  }

  // Specific to Score Filter
  // ------------------------------------------------------------------------ //
  get type()               { return 'score' }
  get position()           { return this.get('position') }

  get hasTwaTFMValues()    { return this.anyPresent(['tfmRangeStart', 'tfmRangeEnd']) }
  get hasTenureValues()    { return this.anyPresent(['tenureRangeStart', 'tenureRangeEnd']) }
  get hasFrequencyValues() { return this.anyPresent(['frequencyRangeStart', 'frequencyRangeEnd']) }
  get hasMonetaryValues()  { return this.anyPresent(['monetaryRangeStart', 'monetaryRangeEnd']) }
  get hasEngagementValues()  { return this.anyPresent(['engagementRangeStart', 'engagementRangeEnd']) }

  get hasAnyValues() {
    return this.anyPresent([
      'tfmRangeStart', 'tfmRangeEnd',
      'tenureRangeStart', 'tenureRangeEnd',
      'frequencyRangeStart', 'frequencyRangeEnd',
      'monetaryRangeStart', 'monetaryRangeEnd',
      'engagementRangeStart', 'engagementRangeEnd',
    ])
  }

  // Summary
  // ------------------------------------------------------------------------ //

  get summarizable() {
    // Only when something has been added
    return this.hasAnyValues && this.hasDateValues
  }

  // Sorting
  // ------------------------------------------------------------------------ //

  get sortables() {
    return {
      ...this.twaTFMSortable,
      ...this.tenureSortable,
      ...this.frequencySortable,
      ...this.monetarySortable,
      ...this.engagementSortable,
    }
  }

  get twaTFMSortable() {
    let obj = {}
    const key = `twa_tfm_${this.position}`
    obj[key] = `[${this.position}] Avg TFM Score`

    return obj
  }

  get tenureSortable() {
    let obj = {}
    const key = `max_tenure_${this.position}`
    obj[key] = `[${this.position}] Tenure Score`

    return obj
  }

  get frequencySortable() {
    let obj = {}
    const key = `twa_frequency_${this.position}`
    obj[key] = `[${this.position}] Avg Frequency`

    return obj
  }

  get monetarySortable() {
    let obj = {}
    const key = `twa_monetary_${this.position}`
    obj[key] = `[${this.position}] Avg Monetary Rank`

    return obj
  }

  get engagementSortable() {
    let obj = {}
    const key = `twa_engagement_${this.position}`
    obj[key] = `[${this.position}] Avg Engagement Rank`

    return obj
  }
}
