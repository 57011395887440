import { Controller } from "@hotwired/stimulus"
import * as d3 from 'd3'
import bb from 'billboard.js/dist/billboard.js'
import merge from 'deepmerge'
import { useIntersection, useWindowResize, useDebounce } from 'stimulus-use'
import { Formatter } from "../utils/formatter"
import { ChartConfig } from '../charts/chart_config'

// Connects to data-controller="graph"
export default class extends Controller {
  static targets = ['container', 'loadbar']

  static values = {
    bindId: String,
    loadEvent: String,
    announce: String,
    config: Object,
    data: Array
  }

  static debounces = ['debouncedResize']

  connect() {
    useWindowResize(this)

    app.graphs ||= {}
    this.name = this.bindId?.replace('#', '')
    this.bbLoaded = false

    this.initConfigHandler()
    this.initForPageContext()

    // handle resizing

    // is the chart hidden? load when visible

    // handle delayed loading (?)
    if (this.shouldLoadImmediately) {
      this.load()
    } else {
      document.addEventListener(this.loadEvent, this.load, { once: true })
    }
  }

  disconnect() {
    this.unregister()
  }

  // Stim-use Callbacks
  // ------------------------------------------------------------------------ //

  // useIntersection callbacks
  appear(entry) {
    this.chart?.flush()
    this.bbLoaded = true
    this.resize()
  }

  disappear(entry) {}

  // useWindowResize
  windowResize({ width, height, event }) {
    this.debouncedResize()
  }


  // Config Handling
  // ------------------------------------------------------------------------ //

  initConfigHandler() {
    this.config = new ChartConfig(
      merge(this.configValue, { bindto: this.bindId }),
      this.dataValue
    )
  }

  get finalConfig() {
    // temporary hack for charts with column data
    if (this.configValue.data?.columns) {
      let cfg = this.config.finalConfig
      delete cfg.data.json
      return cfg
    } else {
      return merge(this.config.finalConfig, { data: { json: this.dataValue } })
    }
  }

  // Chart instance management
  // ------------------------------------------------------------------------ //

  load() {
    if (this.shouldLoad) {
      this.generate()
      this.register()

      this.announceLoaded()
    }
  }

  generate() {
    this.chart = bb.generate(this.finalConfig)
  }

  announceLoaded() {
    this.dispatch('loaded', { detail: { graph: this } })
  }

  // Should only destroy if the BB chart was actually generated
  destroy() {
    if (this.chart && this.bbLoaded) {
      this.chart.destroy()
    }
  }

  change(data) {
    this.changeData = data
    this.rebuild()
  }

  rebuild() {
    this.destroy()
    this.generate()
  }

  register() {
    app.charts.push(this.chart)
    app.graphs[this.name] = this
  }

  unregister() {
    if (Boolean(this.chart)) {
      app.charts?.splice(app.charts.indexOf(this.chart), 1)
      this.destroy()
    }

    if (Boolean(app?.graphs[this.name])) {
      delete app.graphs[this.name]
    }
  }

  initForPageContext() {
    this.tabPanel = this.element.closest('[data-tabs-target="panel"]')

    if (this.isInTabPanel) {
      this.config.renderLazy()
      useIntersection(this, { element: this.tabPanel, eventPrefix: this.bindId })
    } else {
      useIntersection(this, { eventPrefix: this.bindId })
    }
  }

  // change chart type or normalize
  // convert to normalized

  get loadEvent() {
    if (this.hasLoadEventValue) {
      return this.loadEventValue
    } else {
      return 'immediate'
    }
  }

  get isInTabPanel() {
    return Boolean(this.tabPanel)
  }

  get shouldLoad() {
    return !(document.documentElement.hasAttribute("data-turbo-preview"))
  }

  get shouldLoadImmediately() {
    return !this.hasLoadEventValue
  }

  // Data
  // ------------------------------------------------------------------------ //

  // Used with app.dataLookup
  get currentData() {
    return { data: { json: this.dataValue } }
  }

  get chartData() {
    return this.dataValue || this.configValue?.data?.json || this.configValue?.data?.columns
  }

  get insufficientData() {
    return !Boolean(this.chartData) || !Boolean(this.chartData.length)
  }

  // Find the chart container element
  get bindElement() {
    return document.querySelector(this.bindId)
  }

  get bindId() {
    if (this.hasBindIdValue) {
      return `#${this.bindIdValue}`
    } else {
      return `#${this.element.id}`
    }
  }

  // Resizing
  // ------------------------------------------------------------------------ //

  get container() {
    return this.element.parentElement
  }

  get box() {
    return this.container.getClientRects()[0]
  }

  get targetWidth() { return Math.floor(this.box.width) - 16 }
  get targetHeight() { return Math.floor(this.box.height) - 16 }

  resize() {
    this.chart?.resize()
  }

  debouncedResize() { this.resize() }
}
