import Summarizer from '../giving_filter/summarizer'

export default class ScoreFilterSummarizer extends Summarizer {
  buildSummary() {

    let phrase = `[${this.model.get('position')}]`

    if (this.hasTFM) {
      phrase += " Avg TFM Score"
      phrase += ` was ${this.rangeComparePhrase('tfmRange')} ${this.rangeValuePhrase('tfmRange')}`
    }

    if (this.hasTenure) {
      if (this.hasTFM) { phrase += ", and" }

      phrase += " Tenure Score"
      phrase += ` was ${this.rangeComparePhrase('tenureRange')} ${this.rangeValuePhrase('tenureRangeHuman')}`
    }

    if (this.hasFreq) {
      if (this.hasTFM || this.hasTenure) { phrase += ", and" }

      phrase += " Avg Frequency"
      phrase += ` was ${this.rangeComparePhrase('frequencyRange')} ${this.rangeValuePhrase('frequencyRangeHuman')}`
    }

    if (this.hasMon) {
      if (this.hasTFM || this.hasTenure || this.hasFreq) { phrase += ", and" }

      phrase += " Avg Monetary Rank"
      phrase += ` was ${this.rangeComparePhrase('monetaryRange')} ${this.rangeValuePhrase('monetaryRange')}`
    }

    if (this.hasEngage) {
      if (this.hasTFM || this.hasTenure || this.hasFreq || this.hasMon) { phrase += ", and" }

      phrase += " Avg Engagement"
      phrase += ` was ${this.rangeComparePhrase('engagementRange')} ${this.rangeValuePhrase('engagementRange')}`
    }

    if (this.model.hasDateValues) {
      phrase += ` ${this.datePhrase}`
    }

    return phrase
  }

  // determine where "ands" should go
  get hasTFM()    { return this.model.hasTwaTFMValues }
  get hasTenure() { return this.model.hasTenureValues }
  get hasFreq()   { return this.model.hasFrequencyValues }
  get hasMon()    { return this.model.hasMonetaryValues }
  get hasEngage() { return this.model.hasEngagementValues }
}
