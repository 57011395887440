import Summarizer from '../giving_filter/summarizer'

export default class ChangeFilterSummarizer extends Summarizer {
  buildSummary() {

    let phrase = `[${this.model.get('position')}]`

    if (this.model.hasChangeValues) {
      phrase += ' Change in giving from'
    } else {
      phrase += ' Display change in giving from'
    }

    if (this.model.hasDateValues) {
      phrase += ` ${this.datePhrase}`
    }

    phrase += ` compared to the previous period`

    if (this.model.hasChangeValues) {
      phrase += ` was ${this.rangeComparePhrase('amountRange')} ${this.rangePercentPhrase('amountRange')}`
    }

    return phrase
  }
}
