import Validator from '../giving_filter/validator'

export default class ZeroFilterValidator extends Validator {
  get isValid() {
    this.resetErrors()
    // this.validateFundsSelected()

    return this.errors.fields.length < 1
  }
}
