import { Controller } from "@hotwired/stimulus"
import GivingFilterController from './giving_filter_controller'
import PledgeFilterModel from '../../searches/pledge_filter/model'
import debounce from "debounce"

export default class extends GivingFilterController {
  static targets = [
    'campaignField',
    'pledgedRangeStart',
    'pledgedRangeEnd',
    'pledgeReceivedRangeStart',
    'pledgeReceivedRangeEnd',
    'pledgeCompletedRangeStart',
    'pledgeCompletedRangeEnd',
    'dateRange',
    'dateRangeStart',
    'dateRangeEnd',
    'dateModeField',
    'lookbackRange',
    'lookbackRangeStart',
    'lookbackRangeEnd',
    'removed',
    'summary'
  ]

  connect() {
    this.registerGivingFilter()
    this.setEstPosition()

    this.model = new PledgeFilterModel({
      position: this.position
    })

    this.updateModel()

    if (this.data.has('minimized') && this.data.get('minimized') == 1) {
      this.collapse()
    }
  }

  updateModel() {
    this.model.set('campaignId',                this.campaignId)
    this.model.set('campaignName',              this.campaignName)
    this.model.set('pledgedRangeStart',         this.pledgedRangeStartTarget.value)
    this.model.set('pledgedRangeEnd',           this.pledgedRangeEndTarget.value)
    this.model.set('pledgeReceivedRangeStart',  this.pledgeReceivedRangeStartTarget.value)
    this.model.set('pledgeReceivedRangeEnd',    this.pledgeReceivedRangeEndTarget.value)
    this.model.set('pledgeCompletedRangeStart', this.pledgeCompletedRangeStartTarget.value)
    this.model.set('pledgeCompletedRangeEnd',   this.pledgeCompletedRangeEndTarget.value)

    this.updateDateValues()
    super.updateModel()
  }

  get campaignId() {
    return this.campaignFieldTarget.value
  }

  get campaignName() {
    return this.campaignFieldTarget.selectedOptions[0].innerText
  }
}
