import Summarizer from './summarizer'
import Validator from './validator'
import Model from '../giving_filter/model.js'

export default class PledgeFilterModel extends Model {
  // Base model overrides

  constructor(attrs) {
    super(attrs)
    this.summarizer = new Summarizer(this)
    this.validator  = new Validator(this)
  }

  // Specific to Pledge Filter
  // ------------------------------------------------------------------------ //

  get type() { return 'pledge' }

  get hasCampaign() { return this.isPresent('campaignId') }

  get hasPledgedValues()    { return this.anyPresent(['pledgedRangeStart', 'pledgedRangeEnd']) }
  get hasFullPledgedRange() { return this.rangePresent('pledgedRange') }
  get isExactPledged()      { return this.rangeExact('pledgedRange') }

  get hasPledgeReceivedValues()     { return this.anyPresent(['pledgeReceivedRangeStart', 'pledgeReceivedRangeEnd']) }
  get hasFullPledgeReceivedRange()  { return this.rangePresent('pledgeReceivedRange') }
  get isExactPledgeReceived()       { return this.rangeExact('pledgeReceivedRange') }

  get hasPledgeCompletedValues()    { return this.anyPresent(['pledgeCompletedRangeStart', 'pledgeCompletedRangeEnd']) }
  get hasFullPledgeCompletedRange() { return this.rangePresent('pledgeCompletedRange') }
  get isExactPledgeCompleted()      { return this.rangeExact('pledgeCompletedRange') }

  get hasExactPledgeCompletionDate() { return (this.isExactDate || this.isExactLookback) }

  // Summary
  // ------------------------------------------------------------------------ //

  get summarizable() {
    return this.hasCampaign
  }

  // Sorting
  // ------------------------------------------------------------------------ //

  get sortables() {
    return {
      ...this.pledgedSortable,
      ...this.pledgeReceivedSortable,
      ...this.pledgeCompletedSortable,
      ...this.pledgeCompletionDateSortable,
      ...this.pledgeStartDateSortable
    }
  }

  get pledgedSortable() {
    let obj = {}
    const key = `amount_pledged_${this.position}`
    obj[key] = `[${this.position}] Amount pledged`

    return obj
  }

  get pledgeReceivedSortable() {
    let obj = {}
    const key = `pledge_received_${this.position}`
    obj[key] = `[${this.position}] Amount received`

    return obj
  }

  get pledgeCompletedSortable() {
    let obj = {}
    const key = `pledge_completed_${this.position}`
    obj[key] = `[${this.position}] Percent completed`

    return obj
  }

  get pledgeCompletionDateSortable() {
    let obj = {}
    const key = `pledge_completion_date_${this.position}`
    obj[key] = `[${this.position}] Pledge Completion Date`

    return obj
  }

  get pledgeStartDateSortable() {
    let obj = {}
    const key = `pledge_start_date_${this.position}`
    obj[key] = `[${this.position}] Pledge Start Date`

    return obj
  }
}
