import { Controller } from "@hotwired/stimulus"
import SearchModel from '../search/model'

export default class extends Controller {
  static targets = [
    'badge',
    'locationBox',
    'locationsBadge',
    'tagsField',
    'ageRangeStart',
    'ageRangeEnd',
    'ageRangeBadge',
    'householdId',
    'householdName',
    'email',
    'zip',
    'excludeInactives',
    'includeIgnorables',
    'bandBox',
    'givingWell',
    'givingFilter',
    'sortField',
    'sortDirection',
    'fundReference',
    'listsField',
    'tagOpField'
  ]

  connect() {
    window.app.search = this
    window.app.givingFilters = window.app.givingFilters || []
    window.app.funds = this.funds

    this.listenForBadges()

    this.model = new SearchModel()
    this.updateModel()

    this.form = this.element.parentElement
  }

  disconnect() {
    window.app.search = null
  }

  submit() {
    this.form.submit()
  }

  // Data
  // ------------------------------------------------------------------------ //

  updateModel() {
    this.model.set('locationCount', this.locationsCount)
    this.model.set('locationIds', this.locationIds)
    this.model.set('tagIds', this.tagIds)
    this.model.set('ageRangeStart', this.ageRangeStartTarget.value)
    this.model.set('ageRangeEnd', this.ageRangeEndTarget.value)
    this.model.set('householdId', this.householdIdTarget.value)
    this.model.set('householdName', this.householdNameTarget.value)
    this.model.set('email', this.emailTarget.value)
    this.model.set('zip', this.zipTarget.value)
    this.model.set('excludeInactives', this.excludeInactives)
    this.model.set('includeIgnorables', this.includeIgnorables)
    this.model.set('bands', this.bands)
    this.model.set('givingFilters', window.app.givingFilters)
    this.model.set('listIds', this.listIds)
    this.model.set('sortField', this.sortFieldTarget.value)
    this.model.set('sortDirection', this.sortDirectionTarget.value)

    this.data.set('sort-field', this.sortFieldTarget.value)

    // Waits for the next microtask when giving filters should be available
    // https://jakearchibald.com/2015/tasks-microtasks-queues-and-schedules/
    Promise.resolve().then(() => {
      this.updateBadges()
      this.setSortables()
    })
  }

  // Locations
  // ------------------------------------------------------------------------ //

  get locationIds() {
    return this.locationBoxTargets
      .filter(b => b.checked)
      .map(b => b.value)
  }

  updateLocationsBadge() {
    if (!this.registeredBadges.includes('location_ids')) return;

    if (this.locationIds.length == this.locationsCount) {
      this.locationsBadge.update('all', false)
    } else {
      this.locationsBadge.update(this.locationIds.length, true)
    }
  }

  get locationsCount() {
    return this.locationBoxTargets.length
  }

  get locationsBadge() {
    return window.app.badges['location_ids']
  }

  // Tags
  // ------------------------------------------------------------------------ //

  get tagIds() {
    if (!Boolean(document.querySelector('#search_form-tags'))) { return [] }

    return [...this.tagsFieldTarget.options]
      .filter(option => option.selected)
      .map(option => option.value)
  }

  updateTagsBadge() {
    if (!this.registeredBadges.includes('tag_ids')) return;

    if (this.tagIds.length > 0) {
      this.tagsBadge.update(`${this.tagOp} [${this.tagIds.length}]`, true)
    } else {
      this.tagsBadge.update('none', false)
    }
  }

  get tagsBadge() {
    return window.app.badges['tag_ids']
  }

  get tagOp() {
    return this.tagOpFieldTarget.value
  }

  // Lists
  // ------------------------------------------------------------------------ //

  get listIds() {
    if (!Boolean(document.querySelector('#search_form-lists'))) { return [] }

    return [...this.listsFieldTarget.options]
      .filter(option => option.selected)
      .map(option => option.value)
  }

  updateListsBadge() {
    if (!this.registeredBadges.includes('list_ids')) return;

    if (this.listIds.length > 0) {
      this.listsBadge.update(this.listIds.length, true)
    } else {
      this.listsBadge.update('none', false)
    }
  }

  get listsBadge() {
    return window.app.badges['list_ids']
  }

  // Age
  // ------------------------------------------------------------------------ //

  updateAgeBadge() {
    if (!this.registeredBadges.includes('age_range')) return;

    if (this.model.hasAgeValues) {
      this.ageRangeBadge.update(this.ageSummary, true)
    } else {
      this.ageRangeBadge.update('none', false)
    }
  }

  get ageSummary() {
    if (this.model.rangeExact('ageRange')) {
      return this.model.get('ageRangeStart')
    } else if (this.model.hasFullAgeRange) {
      return `${this.model.get('ageRangeStart')}–${this.model.get('ageRangeEnd')}`
    } else if (this.model.isPresent('ageRangeStart')) {
      return `${this.model.get('ageRangeStart')}+`
    } else {
      return `< ${this.model.get('ageRangeEnd')}`
    }
  }

  get ageRangeBadge() {
    return window.app.badges['age_range']
  }

  // Contact
  // ------------------------------------------------------------------------ //

  get excludeInactives() {
    return this.excludeInactivesTarget.checked
  }

  get includeIgnorables() {
    return this.includeIgnorablesTarget.checked
  }

  updateContactBadge() {
    if (!this.registeredBadges.includes('contact')) return;

    if (this.model.hasContactValues) {
      this.contactBadge.update(this.contactSummary, true)
    } else {
      this.contactBadge.update('none', false)
    }
  }

  get contactSummary() {
    let fields = []

    if (this.model.isPresent('householdId')) {
      fields.push('householdId')
    }

    if (this.model.isPresent('householdName')) {
      fields.push('name')
    }

    if (this.model.isPresent('email')) {
      fields.push('email')
    }

    if (this.model.isPresent('zip')) {
      fields.push('zip')
    }

    if (this.excludeInactives) {
      fields.push('hide-inactives')
    }

    if (this.includeIgnorables) {
      fields.push('ignorables')
    }

    return fields.join(', ')
  }

  get contactBadge() {
    return window.app.badges['contact']
  }

  // Bands
  // ------------------------------------------------------------------------ //

  get bands() {
    return this.bandBoxTargets
      .filter(b => b.checked)
      .map(b => parseInt(b.value))
  }

  get bandSummary() {
    if (this.bands.length == 0) return 'any';
    if (this.model.allBandsSelected) return 'all';

    return this.bands.join(', ')
  }

  get bandsBadge() {
    return window.app.badges['bands']
  }

  updateBandsBadge() {
    if (!this.registeredBadges.includes('bands')) return;

    if (!this.model.hasBandValues) {
      this.bandsBadge.update(this.bandSummary, false)
    } else {
      this.bandsBadge.update(this.bandSummary, true)
    }
  }

  // Giving
  // ------------------------------------------------------------------------ //

  get shouldShowGiving() {
    return this.data.has('giving-open') &&
      (this.data.get('giving-open') == 1 || this.data.get('giving-open') == 'true')
  }

  focusOnFilter(event) {
    event.preventDefault()

    this.givingFilterTargets.forEach(gf => gf.classList.add('giving_filter--minimized'))
    let gf = event.currentTarget.closest('.giving_filter')
    if (Boolean(gf)) gf.classList.remove('giving_filter--minimized');
  }

  focusOnNewFilter() {
    let targets = this.givingFilterTargets.slice(0)
    let last = targets.pop()

    targets.forEach(gf => gf.classList.add('giving_filter--minimized'))
  }

  openGiving() {
    this.givingWellTarget.classList.add('filter_well--open')
  }

  // Funds
  // ------------------------------------------------------------------------ //

  get funds() {
    if (this.hasFundReferenceTarget) {
      return JSON.parse(this.fundReferenceTarget.innerHTML)
    } else {
      return {}
    }
  }

  // Sorting
  // ------------------------------------------------------------------------ //

  setSortables() {
    let currentOptions = Array.from(this.sortFieldTarget.children)
    let blank = document.createElement('option')
    blank.value = ''

    let options = [blank]

    for (let [value, name] of Object.entries(this.sortables)) {
      let option = document.createElement('option')
      option.value = value
      option.textContent = name

      if (value == this.data.get('sort-field')) {
        option.setAttribute('selected', 'selected')
      }

      options.push(option)
    }

    currentOptions.forEach(e => e.remove())

    options.forEach(o => this.sortFieldTarget.appendChild(o))
  }

  get householdSortables() {
    let fields = {
      last_name: 'Last name',
      location_name: 'Location',
      last_gift_amount: 'Last gift amount',
      last_gave_on: 'Last gift date'
    }

    if (this.model.hasAgeValues) {
      fields['birthdate'] = 'Birthdate'
    }

    if (this.model.isPresent('householdName')) {
      fields['name_similarity'] = 'Name similarity'
    }

    if (this.model.isPresent('email')) {
      fields['email_similarity'] = 'Email similarity'
    }

    return fields
  }

  get sortables() {
    let fields = Object.assign({}, this.householdSortables)

    window.app.givingFilters.flatMap((g) => {
      if (g.data.get('removed') != 1) {
        Object.assign(fields, g.sortables)
      }
    })

    return fields
  }

  setSort(field, direction) {
    this.sortFieldTarget.value = field
    this.sortDirectionTarget.value = direction
  }

  updateFilterPositions() {
    let currentFilters = window.app.givingFilters.filter(gf => !gf.removed)

    currentFilters.forEach((filter, idx) => {
      let pos = idx + 1

      filter.setPosition(pos)
      filter.updateModel()
    })
  }

  // Badges
  // ------------------------------------------------------------------------ //

  listenForBadges() {
    this.registeredBadges = []

    this.element.addEventListener('badge-connected', (e) => {
      this.registeredBadges.push(e.detail)
    })
  }

  updateBadges() {
    this.updateLocationsBadge()
    this.updateTagsBadge()
    this.updateAgeBadge()
    this.updateContactBadge()
    this.updateBandsBadge()
    this.updateListsBadge()
  }
}
