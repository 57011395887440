import Validator from '../giving_filter/validator'

export default class ScoreFilterValidator extends Validator {
  get isValid() {
    this.resetErrors()
    this.validateDatesSelected()
    this.validateTenureRange()
    this.validateFrequencyRange()
    this.validateMonetaryRange()
    this.validateEngagementRange()
    this.validateTfmRange()

    return this.errors.fields.length < 1
  }

  validateTenureRange() {
    [`tenureRangeStart`, `tenureRangeEnd`].forEach((name) => {
      const value = this.model.getNum(name)

      if (!this.model.isPresent(name)) return;

      if (value < 1 || value > 8) {
        this.errors.fields.push(name)
        this.errors.messages.push(`Tenure should be between 1 and 8`)
      }
    })
  }

  validateFrequencyRange() {
    [`frequencyRangeStart`, `frequencyRangeEnd`].forEach((name) => {
      const value = this.model.getNum(name)

      if (!this.model.isPresent(name)) return;

      if (value < 1 || value > 8) {
        this.errors.fields.push(name)
        this.errors.messages.push(`Frequency should be between 1 and 8`)
      }
    })
  }

  validateMonetaryRange() {
    [`monetaryRangeStart`, `monetaryRangeEnd`].forEach((name) => {
      const value = this.model.getNum(name)

      if (!this.model.isPresent(name)) return;

      if (value < 1 || value > 100) {
        this.errors.fields.push(name)
        this.errors.messages.push(`Monetary Rank should be between 1 and 100`)
      }
    })
  }

  validateEngagementRange() {
    [`engagementRangeStart`, `engagementRangeEnd`].forEach((name) => {
      const value = this.model.getNum(name)

      if (!this.model.isPresent(name)) return;

      if (value < 1 || value > 16) {
        this.errors.fields.push(name)
        this.errors.messages.push(`Engagement Rank should be between 1 and 16`)
      }
    })
  }

  validateTfmRange() {
    [`tfmRangeStart`, `tfmRangeEnd`].forEach((name) => {
      const value = this.model.getNum(name)

      if (!this.model.isPresent(name)) return;

      if (value < 1 || value > 100) {
        this.errors.fields.push(name)
        this.errors.messages.push(`TFM Score should be between 1 and 100`)
      }
    })
  }
}
