import Validator from '../giving_filter/validator'

export default class SingleFilterValidator extends Validator {
  get isValid() {
    this.resetErrors()
    this.validateRangeValues('amountRange', `Gift amount ${this.rangeInvertedMsg}`)

    this.validateNumberValue('amountRangeStart')
    this.validateNumberValue('amountRangeEnd')
    this.validateFundsSelected()

    return this.errors.fields.length < 1
  }
}
