import Summarizer from '../giving_filter/summarizer'

export default class SingleFilterSummarizer extends Summarizer {
  buildSummary() {
    let phrase = `[${this.model.get('position')}] ${this.model.get('occurrence')} gift`

    phrase += ` ${this.fundPhrase}`

    if (this.model.hasAmountValues) {
      phrase += ` of ${this.rangeComparePhrase('amountRange')} ${this.rangeDollarPhrase('amountRange')}`
    }

    if (this.model.hasAmountValues && this.model.hasDateValuesForDateMode) {
      phrase += ' and given'
    } else {
      phrase += ' was given'
    }

    if (this.model.hasDateValues) {
      phrase += ` ${this.datePhrase}`
    }

    return phrase
  }
}
