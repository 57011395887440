import Summarizer from './summarizer'
import Validator from './validator'
import Model from '../../model.js'

export default class GivingFilterModel extends Model {
  // Base model overrides

  constructor(attrs) {
    super(attrs)
  }

  get position() { return this.get('position') }

  // Dates
  // ------------------------------------------------------------------------ //

  get hasDateValues() { return this.hasDateRangeValues || this.hasLookbackValues }
  get isSpecificDateMode() { return this.get('dateMode') == 'specific' }
  get isRelativeDateMode() { return this.get('dateMode') == 'relative' }

  get hasDateRangeValues() { return this.anyPresent(['dateRangeStart', 'dateRangeEnd']) }
  get hasFullDateRange()   { return this.allPresent(['dateRangeStart', 'dateRangeEnd']) }
  get isExactDate()        { return (this.isSpecificDateMode && this.rangeExact('dateRange')) }

  get hasLookbackValues()    { return this.anyPresent(['lookbackRangeStart', 'lookbackRangeEnd'])}
  get hasFullLookbackRange() { return this.allPresent(['lookbackRangeStart', 'lookbackRangeEnd'])}
  get isExactLookback()      { return (this.isRelativeDateMode && this.rangeExact('lookbackRange')) }

  get hasSpecificDateValues() { return this.isSpecificDateMode && this.hasDateRangeValues }
  get hasRelativeDateValues() { return this.isRelativeDateMode && this.hasLookbackValues }
  get hasDateValuesForDateMode() { return this.hasSpecificDateValues || this.hasRelativeDateValues }

  // Funds
  // ------------------------------------------------------------------------ //

  get toAnyFund() {
    const selected = this.get('fundCategoryIds') || []
    const categories = window.app.funds.category_ids || []

    return selected.length == categories.length
  }

  get toDefaultFunds() {
    if (!this.isPresent('fundCategoryIds')) return false;

    let selected = this.get('fundCategoryIds').map(i => this.castNumber(i))
    let defaults = window.app.funds.default_category_ids

    return this.arraysEqual(selected, defaults)
  }

  // Summary
  // ------------------------------------------------------------------------ //

  get summarizable() {
    return false
  }

  get summary() { return this.summarizer.summarize() }

  // Validations
  // ------------------------------------------------------------------------ //

  get isValid() { return this.validator.isValid }
  get errors()  { return this.validator.errors }

  // Sorting
  // ------------------------------------------------------------------------ //

  get sortables() {
    return {}
  }
}
