export default class Summarizer {
  constructor(model) {
    this.model = model
  }

  summarize() {
    if (!this.model.summarizable) return;
    return this.buildSummary()
  }

  get fundPhrase() {
    if (this.model.toAnyFund) {
      return 'to any fund'
    } else if (this.model.toDefaultFunds) {
      return 'to default funds'
    } else {
      return 'to specific funds'
    }
  }

  get datePhrase() {
    if (this.model.hasSpecificDateValues) {
      return this.dateRangePhrase
    } else if (this.model.hasRelativeDateValues) {
      return this.lookbackPhrase
    } else {
      return ''
    }
  }

  get dateRangePhrase() {
    if (this.model.isExactDate) {
      return `on ${this.model.get('dateRangeStart')}`
    } else if (this.model.hasFullDateRange) {
      return `between ${this.model.get('dateRangeStart')} and ${this.model.get('dateRangeEnd')}`
    } else if (this.model.isPresent('dateRangeStart')) {
      return `on or after ${this.model.get('dateRangeStart')}`
    } else {
      return `on or before ${this.model.get('dateRangeEnd')}`
    }
  }

  get lookbackPhrase() {
    if (this.model.isExactLookback) {
      return `exactly ${this.model.get('lookbackRangeStart')} days ago`
    } else if (this.model.hasFullLookbackRange) {
      return `between ${this.model.get('lookbackRangeEnd')} and
        ${this.model.get('lookbackRangeStart')} days ago`
    } else if (this.model.isPresent('lookbackRangeEnd')) {
      return `${this.model.get('lookbackRangeEnd')} or more days ago`
    } else {
      return `up to ${this.model.get('lookbackRangeStart')} days ago`
    }
  }

  rangeDollarPhrase(name) {
    if (this.model.rangeExact(name)) {
      return `$${this.model.get(`${name}Start`)}`
    } else if (this.model.rangePresent(name)) {
      return `$${this.model.get(`${name}Start`)} and $${this.model.get(`${name}End`)}`
    } else {
      return `$${this.model.presentRangeValue(name)}`
    }
  }

  rangeComparePhrase(name) {
    if (this.model.rangeExact(name)) {
      return 'exactly'
    } else if (this.model.rangePresent(name)) {
      return 'between'
    } else if (this.model.isPresent(`${name}Start`)) {
      return 'at least'
    } else {
      return 'at most'
    }
  }

  rangeValuePhrase(name) {
    if (this.model.rangeExact(name)) {
      return this.model.get(`${name}Start`)
    } else if (this.model.rangePresent(name)) {
      return `${this.model.get(`${name}Start`)} and ${this.model.get(`${name}End`)}`
    } else {
      return this.model.presentRangeValue(name)
    }
  }

  rangePercentPhrase(name) {
    if (this.model.rangeExact(name)) {
      return `${this.model.get(`${name}Start`)}%`
    } else if (this.model.rangePresent(name)) {
      return `${this.model.get(`${name}Start`)}% and ${this.model.get(`${name}End`)}%`
    } else {
      return `${this.model.presentRangeValue(name)}%`
    }
  }
}
