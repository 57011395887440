import Summarizer from '../giving_filter/summarizer'

export default class AggregateFilterSummarizer extends Summarizer {
  buildSummary() {
    let phrase = `[${this.model.get('position')}] `

    if (this.model.hasAggValues) {
      phrase += `${this.aggCalcPhrase} giving`
      phrase += ` ${this.fundPhrase}`
    }

    if (this.model.hasAggValues) {
      phrase += ` was ${this.rangeComparePhrase('aggRange')} ${this.rangeDollarPhrase('aggRange')}`
    }

    if (this.model.hasAggValues && this.model.hasCountValues) {
      phrase += ' and'
    }

    if (this.model.hasCountValues) {
      phrase += ` ${this.rangeComparePhrase('countRange')} ${this.countValuePhrase}
        gifts were given`
    }

    if (this.model.hasCountValues && !this.model.hasAggValues) {
      phrase += ` ${this.fundPhrase}`
    }

    if (this.model.hasDateValues) {
      phrase += ` ${this.datePhrase}`
    }

    return phrase
  }

  get aggCalcPhrase() {
    if (this.model.get('aggFunction') == 'sum') {
      return 'Sum of'
    } else {
      return 'Average'
    }
  }

  get countValuePhrase() {
    if (this.model.isExactCount) {
      return this.model.get('countRangeStart')
    } else if (this.model.hasFullCountRange) {
      return `${this.model.get('countRangeStart')} and ${this.model.get('countRangeEnd')}`
    } else {
      return this.model.presentRangeValue('countRange')
    }
  }
}
