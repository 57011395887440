import Validator from '../giving_filter/validator'

export default class AggregateFilterValidator extends Validator {
  get isValid() {
    this.resetErrors()

    this.validateRangeValues('aggRange', `${this.model.get('aggFunction')} ${this.rangeInvertedMsg}`)
    this.validateRangeValues('countRange', `Gift count ${this.rangeInvertedMsg}`)

    this.validateNumberValue('aggRangeStart')
    this.validateNumberValue('aggRangeEnd')
    this.validateNumberValue('countRangeStart')
    this.validateNumberValue('countRangeEnd')
    this.validateFundsSelected()

    return this.errors.fields.length < 1
  }
}
