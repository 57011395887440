import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="searches--numbered-gift"
export default class extends Controller {
  static targets = [ 'numField', 'custom', 'lastGift' ]
  static classes = [ 'numFieldActive', 'numFieldDisabled' ]

  connect() {
    this.toggle()
  }

  updateVal(event) {
    const val = event.target.value

    if(Number(val) === -1) {
      this.lastGiftTarget.value = true
    } else {
      this.lastGiftTarget.value = false
      this.numFieldTarget.value = val
      // console.log(val);
    }

    this.toggle()
    console.log(this.numFieldTarget.value);
  }

  toggle() {
    if(this.customTarget.checked) {
      this.numFieldTarget.classList.remove('hidden')
      // this.numFieldTarget.disabled = false
      this.focusOnNumField()
      this.lastGiftTarget.value = false
    } else {
      // this.numFieldTarget.disabled = true
      this.numFieldTarget.classList.add('hidden')
    }
  }

  // Focus on the num field and move the cursor to the end
  focusOnNumField() {
    this.numFieldTarget.selectionStart = this.numFieldTarget.selectionEnd = this.numFieldTarget.value.length;
    this.numFieldTarget.focus()
  }
}
