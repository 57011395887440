import Validator from '../giving_filter/validator'

export default class ChangeFilterValidator extends Validator {
  get isValid() {
    this.resetErrors()
    this.validateRangeValues('amountRange', `Change amount ${this.rangeInvertedMsg}`)

    this.validateNumberValue('amountRangeStart')
    this.validateNumberValue('amountRangeEnd')

    if (this.model.hasSpecificDateValues){
      this.validateFullDateRange()
    } else if(this.model.hasRelativeDateValues) {
      this.validateFullLookbackRange()
    }

    this.validateFundsSelected()

    return this.errors.fields.length < 1
  }

  validateFullDateRange() {
    if (this.model.hasFullDateRange) {
      return;
    } else if (this.model.isPresent('dateRangeStart')) {
      this.errors.fields.push('dateRangeEnd')
      this.errors.messages.push('must select end date')
    } else {
      this.errors.fields.push('dateRangeStart')
      this.errors.messages.push('must select start date')
    }
  }

  validateFullLookbackRange() {
    if (this.model.hasFullLookbackRange) {
      return;
    } else if (this.model.isPresent('lookbackRangeStart')) {
      this.errors.fields.push('lookbackRangeEnd')
      this.errors.messages.push('must select nearest days ago')
    } else {
      this.errors.fields.push('lookbackRangeStart')
      this.errors.messages.push('must select farthest days ago')
    }
  }
}
