import Model from '../model.js'
// import Summarizer from './summarizer'

export default class SearchModel extends Model {
  // Base model overrides

  constructor(attrs) {
    super(attrs)
    // this.summarizer = new Summarizer(this)
    // this.validator  = new Validator(this)
  }

  get hasContactValues() {
    return this.anyPresent(['householdName', 'email', 'zip', 'householdId']) ||
      this.get('excludeInactives') || this.get('includeIgnorables')
  }

  get hasAgeValues() { return this.anyPresent(['ageRangeStart', 'ageRangeEnd']) }
  get hasFullAgeRange() { return this.rangePresent('ageRange') }

  get locationFiltered() {
    return this.getNum('locationCount') > this.get('locationIds').length
  }

  get tagFiltered() {
    return this.get('tagIds').length > 0
  }

  get hasBandValues() {
    return this.get('bands').length > 0
  }

  get allBandsSelected() {
    return [1,2,3,4,5].every(i => this.get('bands').includes(i))
  }
}
