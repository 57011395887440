export default class Validator {
  constructor(model) {
    this.model = model
    this.resetErrors()
  }

  resetErrors() {
    this.errors = {
      fields: [],
      messages: []
    }
  }

  get isValid() {
    return this.errors.fields.length < 1
  }

  validateRangeValues(range, msg) {
    if (!this.model.rangePresent(range)) return;

    if (this.rangeIsInverted(`${range}Start`, `${range}End`)) {
      this.errors.fields.push(`${range}Start`)
      this.errors.messages.push(msg)
    }
  }

  rangeIsInverted(startVar, endVar) {
    return this.model.getNum(startVar) > this.model.getNum(endVar)
  }

  get rangeInvertedMsg() {
    return 'minimum amount is larger than the maximum'
  }

  validateNumberValue(name) {
    if (!this.model.isPresent(name)) return;

    if (isNaN(Number(this.model.get(name)))) {
      this.errors.fields.push(name)
      this.errors.messages.push(`value is not a number or contains symbols`)
    }
  }

  validateFundsSelected() {
    if (this.model.get('fundCategoryIds').length == 0 && this.model.get('fundIds').length == 0) {
      this.errors.fields.push('fundCategoryId')
      this.errors.messages.push(`No funds or fund categories are selected`)
    }
  }

  validateDatesSelected() {
    if (!this.model.hasDateValues) {
      this.errors.fields.push('dateRangeStart')
      this.errors.messages.push(`You must select a date range for this filter`)
    }
  }

  validateWithinRange(rangeName, min, max) {
    [`${rangeName}Start`, `${rangeName}End`].forEach((name) => {
      const value = this.model.get(name)

      if (!this.model.isPresent(name)) return;

      if (value < min) {
        this.errors.fields.push(name)
        this.errors.messages.push(`value is less than the minimum`)
      }

      if (value > max) {
        this.errors.fields.push(name)
        this.errors.messages.push(`value is greather than the maximum`)
      }
    })
  }
}
