import Summarizer from './summarizer'
import Validator from './validator'
import Model from '../giving_filter/model.js'

export default class SingleFilterModel extends Model {
  // Base model overrides

  constructor(attrs) {
    super(attrs)
    this.summarizer = new Summarizer(this)
    this.validator  = new Validator(this)
  }

  // Specific to Single Filter
  // ------------------------------------------------------------------------ //
  get type()               { return 'single' }

  get position()           { return this.get('position') }
  get isAnyOccurrence()    { return this.get('occurrence') == 'any' }

  get hasAmountValues()    { return this.anyPresent(['amountRangeStart', 'amountRangeEnd']) }
  get hasFullAmountRange() { return this.rangePresent('amountRange') }
  get isExactAmount()      { return this.rangeExact('amountRange') }

  // Summary
  // ------------------------------------------------------------------------ //

  get summarizable() {
    if (this.isAnyOccurrence) {
      return this.isPresent('occurrence')
    } else {
      return this.isPresent('occurrence') && this.hasDateValues
    }
  }

  // Sorting
  // ------------------------------------------------------------------------ //

  get sortables() {
    if (!this.isAnyOccurrence && !this.hasDateRangeValues) return {};

    let obj = {}
    let key

    if (this.isAnyOccurrence) {
      Object.assign(obj, this.anyGiftDateSortable)
    } else {
      Object.assign(obj, this.firstLastGiftDateSortable)
    }

    return obj
  }

  get anyGiftDateSortable() {
    let obj = {}
    let key1 = `entered_on_${this.position}`
    let key2 = `amount_${this.position}`

    obj[key1] = `[${this.position}] Gift entered on`
    obj[key2] = `[${this.position}] Single Amount`

    return obj
  }

  get firstLastGiftDateSortable() {
    let obj = {}
    let key = `${this.get('occurrence')}_gift_on_${this.position}`
    obj[key] = `[${this.position}] ${this.getTitled('occurrence')} gift entered on`

    return obj
  }
}
