import { Controller } from "@hotwired/stimulus"
import GivingFilterController from './giving_filter_controller'
import ScoreFilterModel from '../../searches/score_filter/model'
// import debounce from "debounce"

export default class extends GivingFilterController {
  static targets = [
    'dateRange',
    'dateRangeStart',
    'dateRangeEnd',
    'dateModeField',
    'lookbackRange',
    'lookbackRangeStart',
    'lookbackRangeEnd',
    'removed',
    'summary',
    'tfmRangeStart',
    'tfmRangeEnd',
    'frequencyRangeStart',
    'frequencyRangeEnd',
    'tenureRangeStart',
    'tenureRangeEnd',
    'monetaryRangeStart',
    'monetaryRangeEnd',
    'engagementRangeStart',
    'engagementRangeEnd',
  ]

  connect() {
    this.registerGivingFilter()
    this.setEstPosition()

    this.model = new ScoreFilterModel({
      position: this.position
    })

    this.updateModel()

    if (this.data.has('minimized') && this.data.get('minimized') == 1) {
      this.collapse()
    }
  }

  updateModel() {
    this.model.setNum('tfmRangeStart',             this.tfmRangeStartTarget.value)
    this.model.setNum('tfmRangeEnd',               this.tfmRangeEndTarget.value)
    this.model.setNum('frequencyRangeStart',       this.frequencyRangeStartTarget.value)
    this.model.setNum('frequencyRangeEnd',         this.frequencyRangeEndTarget.value)
    this.model.setAttr('frequencyRangeHumanStart', this.getSelectVal(this.frequencyRangeStartTarget))
    this.model.setAttr('frequencyRangeHumanEnd',   this.getSelectVal(this.frequencyRangeEndTarget))
    this.model.setNum('tenureRangeStart',          this.tenureRangeStartTarget.value)
    this.model.setNum('tenureRangeEnd',            this.tenureRangeEndTarget.value)
    this.model.setAttr('tenureRangeHumanStart',    this.getSelectVal(this.tenureRangeStartTarget))
    this.model.setAttr('tenureRangeHumanEnd',      this.getSelectVal(this.tenureRangeEndTarget))
    this.model.setNum('monetaryRangeStart',        this.monetaryRangeStartTarget.value)
    this.model.setNum('monetaryRangeEnd',          this.monetaryRangeEndTarget.value)
    this.model.setNum('engagementRangeStart',      this.engagementRangeStartTarget.value)
    this.model.setNum('engagementRangeEnd',        this.engagementRangeEndTarget.value)

    this.updateDateValues()
    super.updateModel()
  }

  getSelectVal(selectTarget) {
    const index = selectTarget.selectedIndex

    if (index === 0) {  // Not filtered
      return;
    } else {
      return selectTarget.options[index].text
    }
  }
}
