import Summarizer from '../giving_filter/summarizer'

export default class PledgeFilterSummarizer extends Summarizer {
  buildSummary() {
    let phrase = `[${this.model.get('position')}] `

    // pledged at least $1000 to campaign-name, and at least $250 has been received, and
    // at least 25% of the pledge is completed.

    if (this.model.hasDateValues) {
      phrase += this.completedPledgedPhrase
    } else if (this.model.hasPledgedValues) {
      phrase += `Pledged ${this.pledgedPhrase} to ${this.model.get('campaignName')}`
    } else {
      phrase += `Pledged to ${this.model.get('campaignName')}`
    }

    if (this.model.hasPledgeReceivedValues) {
      phrase += `, and ${this.pledgeReceivedPhrase} has been received`
    }

    if (this.model.hasPledgeCompletedValues) {
      phrase += `, and ${this.pledgeCompletedPhrase} completed`
    }

    return phrase
  }

  get pledgedPhrase() {
    return `${this.rangeComparePhrase('pledgedRange')} ${this.rangeDollarPhrase('pledgedRange')}`
  }

  get completedPledgedPhrase(){
    if (this.model.hasPledgedValues) {
      return `Completed a pledge to ${this.model.get('campaignName')} ${this.datePhrase} of ${this.pledgedPhrase}`
    } else {
      return `Completed a pledge to ${this.model.get('campaignName')} ${this.datePhrase}`
    }
  }

  get pledgeReceivedPhrase() {
    return `${this.rangeComparePhrase('pledgeReceivedRange')} ${this.rangeDollarPhrase('pledgeReceivedRange')}`
  }

  get pledgeCompletedPhrase() {
    return `${this.rangeComparePhrase('pledgeCompletedRange')} ${this.completedValuePhrase}`
  }

  get completedValuePhrase() {
    if (this.model.isExactPledgeCompleted) {
      return `${this.model.get('pledgeCompletedRangeStart')}%`
    } else if (this.model.hasFullPledgeCompletedRange) {
      return `${this.model.get('pledgeCompletedRangeStart')}% and ${this.model.get('pledgeCompletedRangeEnd')}%`
    } else {
      return `${this.model.presentRangeValue('pledgeCompletedRange')}%`
    }
  }
}
