import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {}

  sort() {
    // console.log(this.data.get('sortField') + this.data.get('willSortDirection'))
    app.search.setSort(this.data.get('sortField'), this.data.get('willSortDirection'))
    app.search.submit()
  }

  updateIcon() {
    if (!this.hasStateTarget) { return }
    const icon = this.stateTarget.querySelector('.far')
    if (!Boolean(icon)) { return }

    if (this.isOpen) {
      icon.classList.remove('fa-chevron-down')
      icon.classList.add('fa-chevron-up')
    } else {
      icon.classList.add('fa-chevron-down')
      icon.classList.remove('fa-chevron-up')
    }
  }

  get indicator() {
    return this.indicatorTarget
  }
}
