import Summarizer from './summarizer'
import Validator from './validator'
import Model from '../giving_filter/model.js'

export default class AggregateFilterModel extends Model {
  // Base model overrides

  constructor(attrs) {
    super(attrs)
    this.summarizer = new Summarizer(this)
    this.validator  = new Validator(this)
  }

  // Specific to AggregateFilter Filter
  // ------------------------------------------------------------------------ //

  get type() { return 'aggregate' }

  get hasAggValues()       { return this.anyPresent(['aggRangeStart', 'aggRangeEnd']) }
  get hasFullAggRange()    { return this.allPresent(['aggRangeStart', 'aggRangeEnd']) }
  get isExactAgg()         { return this.rangeExact('aggRange') }

  get hasCountValues()     { return this.anyPresent(['countRangeStart', 'countRangeEnd']) }
  get hasFullCountRange()  { return this.allPresent(['countRangeStart', 'countRangeEnd']) }
  get isExactCount()       { return this.rangeExact('countRange') }

  // Summary
  // ------------------------------------------------------------------------ //

  get summarizable() {
    return (this.isPresent('aggFunction') && this.hasAggValues) || this.hasCountValues
  }

  // Sorting
  // ------------------------------------------------------------------------ //

  get sortables() {
    let obj = {}

    if (this.sortableByAggregate) { Object.assign(obj, this.aggSortable) }
    if (this.sortableByGiftCount) { Object.assign(obj, this.giftCountSortable) }

    return obj
  }

  get aggSortable() {
    let obj = {}
    let aggKey = `amount_${this.get('aggFunction')}_${this.position}`
    obj[aggKey] = `[${this.position}] Amount ${this.get('aggFunction')}`

    return obj
  }

  get giftCountSortable() {
    let obj = {}
    let countKey = `gift_count_${this.position}`
    obj[countKey] = `[${this.position}] Gift count`

    return obj
  }

  get sortableByAggregate() {
    return this.hasAggValues
  }

  get sortableByGiftCount() {
    return this.hasCountValues
  }
}
