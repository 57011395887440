import Summarizer from '../giving_filter/summarizer'

export default class ZeroFilterSummarizer extends Summarizer {
  buildSummary() {

    let phrase = `[${this.model.get('position')}] No giving`

    phrase += `, ${this.fundPhrase}`

    if (this.model.hasDateValues) {
      phrase += `, ${this.datePhrase}`
    } else {
      phrase += `, ever`
    }

    return phrase
  }
}
