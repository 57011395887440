export default class RelativeRangeSummarizer {
  constructor({ min, max, showComp }) {
    this.min = min
    this.max = max
    this.showComp = showComp
  }

  summary() {
    let str
    // if the max value is 0 just say "Last N days"
    // if there's a max value then say "Between MIN and MAX days ago"
    if (this.max > 0) {
      str = `Between ${this.min} and ${this.max} days ago`
    } else {
      str = `In the last ${this.min} days`
    }

    if (this.showComp) {
      str += `<div class="text-xs text-slate-400">compared to ${this.compSummary} </div>`
    }

    return str
  }

  get compSummary() {
    const [min, max] = this.compRange

    return `${min} to ${max} days ago`
  }

  get compRange() {
    const dayDiff = this.max - this.min
    const min = this.min + 1
    const max = this.min - dayDiff

    return [ min, max ]
  }
}
