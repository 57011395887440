// See Ninja Keys https://github.com/ssleptsov/ninja-keys#usage

import { Controller } from "@hotwired/stimulus"
import { useDebounce } from 'stimulus-use'
import { get } from '@rails/request.js'
import 'ninja-keys'

// FontAwesome cannot "penetrate" the ninja-keys shadow dom, so we must inject
// the straight svg to get FA icons. Imported icons here must be mirrored in
// the library.add call in initialize() below.
import { library, icon } from '@fortawesome/fontawesome-svg-core'
import {
  faBolt,
  faChartMixed,
  faFamily,
  faList,
  faMagnifyingGlassDollar,
  faPeopleRoof,
  faMapLocation,
  faHandsHoldingDollar,
  faSquareCheck,
  faPaperPlane,
  faUsersViewfinder,
  faGear,
  faTags,
  faInfoCircle,
  faMemo
} from '@fortawesome/pro-light-svg-icons'


// Connects to data-controller="cmd-palette"
export default class extends Controller {
  static values = {
    url: String
  }

  static debounces = ['search']

  initialize() {
    library.add(
      faBolt,
      faChartMixed,
      faFamily,
      faList,
      faMagnifyingGlassDollar,
      faPeopleRoof,
      faMapLocation,
      faHandsHoldingDollar,
      faSquareCheck,
      faPaperPlane,
      faUsersViewfinder,
      faGear,
      faTags,
      faInfoCircle,
      faMemo
    )
  }

  connect() {
    useDebounce(this, { wait: 200 })

    app.cmd = this

    this.element.data = this.appFeatures
    this.element.addEventListener('change', this.change)
  }

  change = (event) => {
    this.search(event.detail.search);
  }

  open(event) {
    this.element.open()
  }

  // Uses Request.js
  // https://github.com/rails/request.js#how-to-use
  async search(term) {
    if (!term.length) { return }

    const response = await get(this.urlValue, { query: { term: term }, responseKind: 'json' })

    if (response.ok) {
      const items = this.buildItems(await response.json)

      this.element.data = items
    }
  }

  buildItems(items) {
    const households = this.buildHouseItems(items.filter((i) => i.searchable_type == 'DonorUnit'))
    const docs  = this.buildDocItems(items.filter((i) => i.searchable_type == 'Doc' ))
    // build searches, lists, etc.

    // Need access control for Notes
    // const notes = this.buildNoteItems(items.filter((i) => i.searchable_type == 'Note'))

    return this.appFeatures.concat(docs, households)
  }

  buildDocItems(items) {
    return items.map((i) => {
      return {
        section: 'Help and Documentation',
        id: i.searchable_id,
        title: i.data.name,
        keywords: String(i.data.keywords),
        icon: this.icn('info-circle'),
        handler: () => Turbo.visit(`/${i.data.path}/${i.data.slug}`)
      }
    })
  }

  // buildNoteItems(items) {
  //   return items.map((i) => {
  //     return {
  //       section: 'Notes',
  //       id: i.searchable_id,
  //       title: i.data.name,
  //       keywords: String(i.data.keywords),
  //       icon: this.icn('memo'),
  //       handler: () => Turbo.visit(`/${i.data.path}/${i.searchable_id}`)
  //     }
  //   })
  // }

  buildHouseItems(items) {
    return items.map((i) => {
      return {
        section: 'Households',
        id: i.searchable_id,
        title: i.data.name,
        icon: this.icn('family'),
        handler: () => Turbo.visit(`/${i.data.path}/${i.searchable_id}`)
      }
    })
  }

  // Finds and builds svg for an FA icon
  // https://fontawesome.com/docs/apis/javascript/methods#icon-icondefinition-params
  icn(name, style = 'fal') {
    return icon(
      {
        prefix: style,
        iconName: name
      },
      { classes: ['ninja-icon'] }
    ).html[0]
  }

  // The default set of items
  get appFeatures() {
    return [
      {
        section: 'Reports',
        id: 'home',
        title: 'Home',
        icon: this.icn('chart-mixed'),
        handler: () => Turbo.visit(`/dashboard`)
      },
      {
        section: 'Reports',
        id: 'benchmarks',
        title: 'Benchmarks Report',
        icon: this.icn('chart-mixed'),
        handler: () => Turbo.visit(`/topics/benchmarks`)
      },
      {
        section: 'Reports',
        id: 'giving',
        title: 'Giving Breakdown',
        icon: this.icn('chart-mixed'),
        handler: () => Turbo.visit(`/topics/giving`)
      },
      {
        section: 'Reports',
        id: 'change',
        title: 'Change Drivers',
        icon: this.icn('chart-mixed'),
        handler: () => Turbo.visit(`/topics/change`)
      },
      {
        section: 'Reports',
        id: 'new_givers',
        title: 'New Givers',
        icon: this.icn('chart-mixed'),
        handler: () => Turbo.visit(`/topics/new_givers`)
      },
      {
        section: 'Reports',
        id: 'retention',
        title: 'Retention',
        icon: this.icn('chart-mixed'),
        handler: () => Turbo.visit(`/topics/retention`)
      },
      {
        section: 'Reports',
        id: 'summaries',
        title: 'Summaries Report',
        icon: this.icn('chart-mixed'),
        handler: () => Turbo.visit(`/topics/summaries`)
      },
      {
        section: 'Reports',
        id: 'core_givers',
        title: 'Core Givers',
        icon: this.icn('chart-mixed'),
        handler: () => Turbo.visit(`/topics/core_givers`)
      },
      {
        section: 'Reports',
        id: 'top_givers',
        title: 'Top Givers',
        icon: this.icn('chart-mixed'),
        handler: () => Turbo.visit(`/topics/top_givers`)
      },
      {
        section: 'Reports',
        id: 'maps',
        title: 'Community Explorer',
        icon: this.icn('map-location'),
        keywords: 'community, explorer, maps, geo geography, city',
        handler: () => Turbo.visit(`/maps`)
      },
      {
        section: 'Campaigns',
        id: 'campaigns',
        title: 'Campaigns',
        icon: this.icn('hands-holding-dollar'),
        handler: () => Turbo.visit(`/campaigns`)
      },
      {
        section: 'Giver Development',
        id: 'households',
        title: 'View Households',
        icon: this.icn('people-roof'),
        handler: () => Turbo.visit(`/households`)
      },
      {
        section: 'Giver Development',
        id: 'activity',
        title: 'Activity',
        icon: this.icn('people-roof'),
        handler: () => Turbo.visit(`/households`)
      },
      {
        section: 'Giver Development',
        id: 'searches',
        title: 'Advanced Searching',
        icon: this.icn('magnifying-glass-dollar'),
        handler: () => Turbo.visit(`/searches`)
      },
      {
        section: 'Giver Development',
        id: 'lists',
        title: 'Lists',
        icon: this.icn('list'),
        handler: () => Turbo.visit(`/lists`)
      },
      {
        section: 'Giver Development',
        id: 'tasks',
        title: 'Tasks',
        icon: this.icn('square-check'),
        handler: () => Turbo.visit(`/tasks`)
      },
      {
        section: 'Giver Development',
        id: 'triggers',
        title: 'Triggers',
        icon: this.icn('bolt'),
        handler: () => Turbo.visit(`/triggers`)
      },
      {
        section: 'Giver Development',
        id: 'emails',
        title: 'Emails',
        icon: this.icn('paper-plane'),
        keywords: 'communication, bulk, mass, outreach, email, mail',
        handler: () => Turbo.visit(`/comms/email_batches`)
      },
      {
        section: 'Giver Development',
        id: 'screening',
        title: 'Giver Screening',
        icon: this.icn('users-viewfinder'),
        keywords: 'wealth, markers, screening, appends, appending',
        handler: () => Turbo.visit(`/screening`)
      },
      {
        section: 'Giver Development',
        id: 'tags',
        title: 'Tags',
        icon: this.icn('tags'),
        handler: () => Turbo.visit(`/tags`)
      },
      {
        section: 'Settings',
        id: 'settings',
        title: 'Settings',
        icon: this.icn('gear'),
        keywords: 'profile, users, thresholds, billing, communication, locations, tags, api, data, funds',
        handler: () => Turbo.visit(`/settings`)
      },
    ]
  }
}
